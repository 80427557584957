const solutionitems = [
  {
    "id": 19,
    "img_src": "/images/blogs/ds01.jpg",
    "img_src1": "/images/artwork/ds01.jpg",
    "img_src2": "/images/artwork/ds01bc.jpg",
    "blogtype": "ML Ai",
    "arialabel": "ds01-gif",
    "name": "Data Science Introduction - Chapter 1",
    "desc": " This blog is related to Data Science Introduction",
    "blogdate": "March 20,2023",
    "deckName": "/blogs/DS01.pdf",
    "videoName": "https://www.youtube.com/embed/j3oCDsntBP4"
  },
  {
    "id": 20,
    "img_src": "/images/blogs/ds02.jpg",
    "img_src1": "/images/artwork/ds02.jpg",
    "img_src2": "/images/artwork/ds02bc.jpg",
    "blogtype": "ML Ai",
    "arialabel": "ds02-gif",
    "name": "Data Science - Data Preprocessing- Chapter 2",
    "desc": " This blog is related to Data Processing - In machine learning, data transformation refers to the process of modifying or manipulating the raw data to make it more suitable or informative for a specific machine learning algorithm or task. Data transformation techniques aim to improve the quality, representation, or distribution of the data, which can lead to better model performance and more accurate predictions.",
    "blogdate": "Jun 20,2023",
    "deckName": "/blogs/DS02.pdf",
    "videoName": "https://www.youtube.com/embed/BibPG1kUA0A"
  },
  {
    "id": 21,
    "img_src": "/images/blogs/ds03.png",
    "img_src1": "/images/artwork/ds03.png",
    "img_src2": "/images/artwork/ds03bc.png",
    "blogtype": "ML Ai",
    "arialabel": "ds03-gif",
    "name": "Data Science - Data Exploration & Visualization - Chapter 3",
    "desc": " This blog is related to Data Exploration & Visualization  - In machine learning Data exploration and visualization involve analyzing datasets to uncover patterns, trends, and insights. Through techniques like statistical summaries and visual tools that helps identify relationships between variables, detect outliers, and understand the distribution of data. Visualization plays a crucial role in presenting complex information in a clear and interpretable manner.",
    "deckName": "/blogs/DS03.pdf",
    "videoName": "https://www.youtube.com/embed/O28jMVqBu3w"
  },
  {
    "id": 22,
    "img_src": "/images/blogs/ds04.png",
    "img_src1": "/images/artwork/ds04.png",
    "img_src2": "/images/artwork/ds04bc.png",
    "blogtype": "ML Ai",
    "arialabel": "ds04-gif",
    "name": "Data Science - Data Management and Stream Analytics - Chapter 4",
    "desc": " This blog delves into the critical role of data management tools, platforms, and data structures in the fields of ML and Data Science.",
    "deckName": "/blogs/DS04.pdf",
    "videoName": "https://www.youtube.com/embed/sW6cvJiGaYA"
  },
    {
      "id": 1,
      "img_src": "/images/blogs/homeauto1.jpg",
      "img_src1": "/images/artwork/homeauto.gif",
      "img_src2": "/images/artwork/homeauto_bc.gif",
      "blogtype": "digital",
      "arialabel": "oracle-gif",
      "name": "A complete home automation solution framework using Hassio",
      "desc": " Using raspberry pi hassio image, nodemcu and 8 channel relay,create a home automation solution using this beautifull framework that supports both MQTT & Websocket",
      "blogdate": "March 29,2020",
      "deckName": "/blogs/homeauto.pdf",
      "videoName": ""
    },
    {
      "id": 2,
      "img_src": "/images/blogs/mpu.png",
      "img_src1": "/images/artwork/mpu.gif",
      "img_src2": "/images/artwork/mpu_bc.gif",
      "blogtype": "iot",
      "arialabel": "mpu-gif",
      "name": "Visualize NODEMCU plugged MPU6050's realtime movement in OLED display",
      "desc": "Use MPU6050 Sensor with NodeMCU to measure several outputs in IoT cloud, as well as display them In OLED display and in Processing Graphics",
      "blogdate": "May 9,2020",
      "deckName": "/blogs/mpu.pdf",
      "videoName": "https://www.youtube.com/embed/KB-kvpJiRkk"
    },
    {
      "id": 3,
      "img_src": "/images/blogs/flow.jpg",
      "img_src1": "/images/artwork/flow.gif",
      "img_src2": "/images/artwork/flow_bc.gif",
      "blogtype": "iot",
      "arialabel": "flow-gif",
      "name": "Measure flowrates using    Particle Photon",
      "desc": "In this blog, I have explained how I have connected an water flow sensor with Particle Photon iOT board to measure the flow rate of fluid.",
      "blogdate": "March 20,2019",
      "deckName": "/blogs/flow.pdf",
      "videoName": "https://www.youtube.com/embed/BglsMC9VSYc"
    },
    {
      "id": 4,
      "img_src": "/images/blogs/rpicnc.jpg",
      "img_src1": "/images/artwork/cnc.gif",
      "img_src2": "/images/artwork/cnc_bc.gif",
      "blogtype": "iot",
      "arialabel": "cnc-gif",
      "name": "Meet My 3018 RPi controlled    CNC Machine",
      "desc": "This is a Raspberry Pi controlled  3D CNC machine, That can cut wood/soft metal against any 3D design also it is used as a Laser engraving CNC.",
      "blogdate": "Jan 15,2019",
      "deckName": "/blogs/cnc.pdf",
      "videoName": "https://www.youtube.com/embed/Z74EMp_O6PE"
    },
    {
      "id": 5,
      "img_src": "/images/blogs/rpieco.jpg",
      "img_src1": "/images/artwork/rpieco.gif",
      "img_src2": "/images/artwork/rpieco_bc.gif",
      "blogtype": "iot",
      "arialabel": "rpieco-gif",
      "name": "Meet My Raspberry Pi programmed Alexa",
      "desc": "In this blog, I have explained how I have converted my one old raspberry pi to an amazon echo dot like device for interactiveflag communications to build AI system",
      "blogdate": "Jun 15,2018",
      "deckName": "/blogs/rpieco.pdf",
      "videoName": "https://www.youtube.com/embed/wMgDECtDY3A"
    },
    {
      "id": 6,
      "img_src": "/images/blogs/3dprint.jpg",
      "img_src1": "/images/artwork/3dprint.gif",
      "img_src2": "/images/artwork/3dprint_bc.gif",
      "blogtype": "iot",
      "arialabel": "3dprint-gif",
      "name": "Meet My Live DIY 3D Printer",
      "desc": "I have assembled and built this first 3D printer using DIY techniques. All parts details and 3D print video are present in this blog.",
      "blogdate": "Mar 25,2018",
      "deckName": "/blogs/3dprint.pdf",
      "videoName": "https://www.youtube.com/embed/MdcN8gyEG5I"
    },
    {
      "id": 7,
      "img_src": "/images/blogs/drone.jpg",
      "img_src1": "/images/artwork/drone.gif",
      "img_src2": "/images/artwork/drone_bc.gif",
      "blogtype": "iot",
      "arialabel": "drone-gif",
      "name": "Meet My DIY Drone (Quadcopter)",
      "desc": "Recently I have assembled and build my first drone. I am trying to share my experience with that build in this blog with all components details",
      "blogdate": "March 15,2018",
      "deckName": "/blogs/drone.pdf",
      "videoName": "https://www.youtube.com/embed/JwUf6hY9Pyw"
    },
    {
      "id": 8,
      "img_src": "/images/blogs/mybot.jpg",
      "img_src1": "/images/artwork/bot.gif",
      "img_src2": "/images/artwork/bot_bc.gif",
      "blogtype": "iot",
      "arialabel": "bot-gif",
      "name": "Meet My home surveillance",
      "desc": "This is one small DIY project where I have develop one small home robot. Like to share the details with you all in this blog. This robot is helping in home surveillance.",
      "blogdate": "May 4,2017",
      "deckName": "/blogs/mybot.pdf",
      "videoName": ""
    },
    {
      "id": 9,
      "img_src": "/images/blogs/chatbot.jpg",
      "img_src1": "/images/artwork/chatbot.gif",
      "img_src2": "/images/artwork/chatbot_bc.gif",
      "blogtype": "digital",
      "arialabel": "chatbot-gif",
      "name": "Errora - An useful Chatbot",
      "desc": "This Chat bot is developed for oracle DBAs and oracle developers. This is developed using RECAST Ai and Heroku.",
      "blogdate": "August 1,2018",
      "deckName": "/blogs/chatbot.pdf",
      "videoName": ""
    },
    {
      "id": 10,
      "img_src": "/images/blogs/isg.png",
      "img_src1": "/images/artwork/isg.gif",
      "img_src2": "/images/artwork/isg_bc.gif",
      "blogtype": "digital",
      "arialabel": "isg-gif",
      "name": "REST in PL/SQL   using Integrated   SOA gateway",
      "desc": "Oracle ERP Suite Integrated SOA Gateway (ISG) is an Out-of-the-Box module that enables supported interface types published in Oracle Integration Repository.",
      "blogdate": "February 14,2017",
      "deckName": "/blogs/isg.pdf",
      "videoName": ""
    },
    {
      "id": 11,
      "img_src": "/images/blogs/mobile.png",
      "img_src1": "/images/artwork/mobile.gif",
      "img_src2": "/images/artwork/mobile_bc.gif",
      "blogtype": "digital",
      "arialabel": "mobile-gif",
      "name": "Build Hybrid Mobile Apps  using Oracle Jet",
      "desc": "JET is a very good javascript framework that includes support for hybrid mobile applications that run on iOS, Android, and Windows mobile devices",
      "blogdate": "February 14,2017",
      "deckName": "/blogs/jetmobile.pdf",
      "videoName": ""
    },
    {
      "id": 12,
      "img_src": "/images/blogs/oracledb.jpg",
      "img_src1": "/images/artwork/oracledb.gif",
      "img_src2": "/images/artwork/oracle_bc.gif",
      "blogtype": "digital",
      "arialabel": "oracledb-gif",
      "name": "Explore oracledb – node.js opensource Library",
      "desc": "In this blog, an alternative approach is given, that helps to connect Oracle database from Oracle Jet without using ORDS and APEX, leveraging  Node.Js open source  library called oracledb.",
      "blogdate": "December 6,2016",
      "deckName": "/blogs/oracledb.pdf",
      "videoName": ""
    },
    {
      "id": 13,
      "img_src": "/images/blogs/jet.jpg",
      "img_src1": "/images/artwork/jet.gif",
      "img_src2": "/images/artwork/jet_bc.gif",
      "blogtype": "digital",
      "arialabel": "jet-gif",
      "name": "Oracle REST service with  Oracle Jet",
      "desc": "Oracle JET provide a simple and efficient platform to the advanced JavaScript developers to build responsive applications.",
      "blogdate": "December 1,2016",
      "deckName": "/blogs/apex.pdf",
      "videoName": ""
    },
    {
      "id": 14,
      "img_src": "/images/blogs/subscript.png",
      "img_src1": "/images/artwork/subscript.gif",
      "img_src2": "/images/artwork/subscript_bc.gif",
      "blogtype": "digital",
      "arialabel": "subscript-gif",
      "name": "Subscription management  improvements in E-biz 12.2",
      "desc": "In this blog, I will explain all new features of subscription commerce that was added in oracle 12.2.x.",
      "blogdate": "November 21,2016",
      "deckName": "/blogs/subscript.pdf",
      "videoName": ""
    },
    {
      "id": 15,
      "img_src": "/images/blogs/iot2.gif",
      "img_src1": "/images/artwork/iot.gif",
      "img_src2": "/images/artwork/iot_bc.gif",
      "blogtype": "iot",
      "arialabel": "iot-gif",
      "name": "Internet of Things – Build your own platform",
      "desc": "The Internet of Things is one of the latest evolution of the Internet, that describes the variety of devices connected through the Internet. For details refer this blog.",
      "blogdate": "December 1,2016",
      "deckName": "/blogs/iot.pdf",
      "videoName": "https://www.youtube.com/embed/BglsMC9VSYc"
    },
    {
      "id": 16,
      "img_src": "/images/blogs/endeca.png",
      "img_src1": "/images/artwork/endeca.gif",
      "img_src2": "/images/artwork/endeca_bc.gif",
      "blogtype": "digital",
      "arialabel": "endeca.gif",
      "name": "ENDECA extention for Oracle E-business",
      "desc": "In this blog, I have explained how to install ENDECA V3 on top of oracle E-business 12.2.x",
      "blogdate": "March 20,2018",
      "deckName": "/blogs/endeca.pdf",
      "videoName": ""
    },
    {
      "id": 17,
      "img_src": "/images/blogs/ecc1.png",
      "img_src1": "/images/artwork/ecc.gif",
      "img_src2": "/images/artwork/ecc_bc.gif",
      "blogtype": "digital",
      "arialabel": "ecc-gif",
      "name": "Enterprise command center for Oracle E-business",
      "desc": "In this blog, I have explained how to install Enterprise command center V3 on top of oracle E-business 12.2.x",
      "blogdate": "March 20,2020",
      "deckName": "/blogs/ecc.pdf",
      "videoName": ""
    },
    {
      "id": 18,
      "img_src": "/images/blogs/oracle.jpg",
      "img_src1": "/images/artwork/oracle.gif",
      "img_src2": "/images/artwork/oracle_bc.gif",
      "blogtype": "digital",
      "arialabel": "oracle-gif",
      "name": "Oracle RDBMS Journey and Changed Features",
      "desc": " This blog is related to oracle RDBMS journey from Oracle 1 to lates 19c database. Between each releases -  I have highlighted major changed features",
      "blogdate": "March 29,2020",
      "deckName": "/blogs/oracle.pdf",
      "videoName": ""
    }
  ];

  export default solutionitems;