import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Helmet } from "react-helmet";
import "../styles.css";
import Footer from "./Footer";
import Header from "./Header";
import Skill from "./Skill";
import skills, { functional } from "./skillitem";

const TITLE = "Lets Confer | About me";

function About() {
  return (
    <div className="App">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="layout">
        <Header />
        <div className="card">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Summary</h5>
              <p className="card-text">
              In my role as Senior Director at Cognizant's Digital Technology Office, I oversee the development and implementation of innovative intellectual property 
              (IP) solutions in the fields of Industry 4.0, Extended Reality (XR), Conversational AI, Responsible Generative AI, Internet of Things (IoT) (Digital Twin), 
              and Cognitive Automation. Having worked in digital engineering and IT for more than 26 years, I have a proven track record of overseeing big, 
              challenging projects for clients throughout the world in a variety of sectors and areas. Building technological strategies and enabling our present staff, 
              together with positioning our ventures, sales and marketing in the dynamic and competitive digital market, are all part of my objective. <br></br>
I oversee a few of our IP solutions, which include:
<ul>
<li>Auto ML/Gen AI Platform: This facilitates deep learning capabilities, hyperparameter tuning, feature engineering, pre-processing, profiling, enrichment, wrangling, visualisation, model building, and automated data integration. facilitates the creation of Generative AI LLM and Vision AI models as well.
</li><li>Industry 4.0 and Supply Chain Twin: these allow data to be stored in downstream systems like Data Lakes, supporting time series insights, ML prediction on batch/stream data, data visualisation and IoT analytics, and many other features like digital twin visualisation on top of GIS and map interfaces. They also support live data stream integration from edge devices and from various enterprise platforms like Oracle, SAP, Salesforce, etc. For 3D models, XR visualisation is also accessible. Using this platform, we have created numerous solutions, such as Digital Green and Clean, Smart Factory, Smart Home, Smart Utility, and many more. 
</li><li>AI-driven virtual assistant: facilitates enterprise integrations, cloud deployment, visual bot creation, and end-to-end lifecycle management Simple live agent and other business application integration. supports voice-to-text and text-to-speech, as well as multi-channel and multi-language functionality and LLM connection. 
</li>
</ul> 
<b>My Current Skills : </b>
              <ul>
              <li>Cloud – AWS, Azure, GCP, Oracle
              </li><li> ML/ Ai – ANN, CNN, RNN, LSTM, GRU, BERT, Auto Encoders, GAN, Transformer, Attention models . Expert in GPT, Bedrock, prompt engineering, LLM Fine-tuning, Lang chain, Vector DB, ML mathematics, statistics and in data science and in Data visualisation.
              </li><li> Multiple Conversational Ai platform 
              </li><li> IoT - Digital Twin 
              </li><li> Industry 4.0 and Supply Chain in Manufacturing, logistics, utilities
              </li><li> Multiple Database and Big-data
              </li><li>XR – AR/VR/MR – Unity, Unreal</li>
             
                <li>
                  Large Program Management in the field of Oracle and several
                  digital technologies that includes ERP, SCM Products (OTM,
                  WMS, ASCM, Demantra) implementations, Upgrades and their Life
                  cycle management
                </li>
                <li>
                  Advisory services in the area of - Product evaluations & GAP
                  fitment study, Build vs Buy analysis, ROI Analysis, Business
                  Process Study and Design, Business process re-engineering
                </li>
                <li>
                  Expert in Data center, Oracle Apps instance consolidation,
                  Database & UNIX administration, Infrastructure capacity
                  planning and in performance tuning.
                </li>
                <li>
                  Huge experience in Building new products/ solutions/ assets
                  around E-business, OTM and in latest key Digital Technologies
                  that includes - IoT, Blockchain, Mobile & Web App, Chatbot,
                  ML/AI etc.
                </li>
                I have work experiences in following overseas countries –
                Australia, USA, Tanzania, Ireland, Switzerland, Hungary, Brazil,
                Singapore and UAE.
                </ul>
              </p>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated :  20th Dec,2024</small>
            </div>
          </div>
        </div>
        <div className="card-deck">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">My Past Technical Skills</h5>
              <div className="card-text">
                <table className="table table-striped table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Area</th>
                      <th>Skill Details</th>
                      <th>Ratings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skills.map(function(item, index) {
                      return (
                        <Skill
                          key={index}
                          Area={item.Area}
                          Skill={item.Skill}
                          Score={item.Score}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated :  10th March,2020</small>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">My Functional Skills</h5>
              <div className="card-text">
                <table className="table table-striped table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Area</th>
                      <th>Skill Details</th>
                      <th>Ratings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {functional.map(function(item, index) {
                      return (
                        <Skill
                          key={index}
                          Area={item.Area}
                          Skill={item.Skill}
                          Score={item.Score}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated :  10th March,2020</small>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Customer Relationship</h5>
              <div className="card-text">
                <img src="/images/customer.png" alt="global customers" />
              </div>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated :  10th March,2020</small>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Oracle E-business & OTM solutions</h5>
              <div className="card-text">
                <li>
                  Oracle EAM & Field Service Integration solutions for utility
                  companies (Linear asset management solutions) - Year 2015
                </li>
                <li>
                  Integrated Mine operations planning & scheduling tool
                  integration with Oracle EAM (Opencast & Underground mines) -
                  Year 2010
                </li>
                <li>
                  Oracle Apps Instance Consolidation Tool - Year 2002, and
                  modified in 2012
                </li>
                <li>
                  Oracle Apps Upgrade Impact Assessment tool Year 2002,
                  re-modified 2015
                </li>
                <li>
                  Oracle Apps Capacity Sizing Tool - Year 2005, re-modified 2015
                </li>
                <li>Automated OPM MAC to ADR conversion - Year 2014</li>
                <li>
                  ERP Audit Portal (SOX compliance) to detect ERP vulnerability
                  and SOX violations & remediation (200+ scripts) - Year 2013
                </li>
                <li>
                  Oracle Apps DBA Monitoring & Patch-clone automation Toolkit –
                  for Monitoring/debug/troubleshooting/tracing (300+ scripts) –
                  Year 2001 to 2004
                </li>
                <li>
                  RICE Factory Demand & Capacity Management Tool – this is a
                  portal developed for ATP checks on new work packs, service
                  catalogs & estimation - Year 2014
                </li>
                <li>
                  OTM with non-oracle SSO/LDAP integration solutions - Year 2011
                </li>
                <li>OTM Rate Loader - Year 2011</li>
                <li>
                  OTM Financial Portal for Financial integrations - Year 2011
                </li>
                <li>
                  Custom E-business mobile apps using Oracle Jet Cordova (Hybrid
                  solution) - year 2017
                </li>
                <li>
                  E-biz REST service invocation using free opensource tech stack
                  like NodeJs (Oracledb) – year 2017
                </li>
                <li>
                  Custom system Admin data related – Endeca Discovery Dashboards
                  and integrated with Oracle Apps – year 2016
                </li>
                <li>Move order IOT solutions for DWTC – year 2017</li>
                <li>
                  OTM Dynamic MAP and OTM with Blockchain IOT cloud integration
                  – year 2018
                </li>
              </div>
            </div>
            <div className="card-footer">
              <small className="text-muted">Last updated :  10th March,2020</small>
            </div>
          </div>
          <div className="card">
          <div className="card-body">
            <h5 className="card-title">My Passions</h5>
            <div className="card-text">
              <div>
                <li> Music, Playing Guitar and Sing</li>
                <li> Photography & Painting</li>
                <li> Writing Poem and Drama </li>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <small className="text-muted">Last updated :  10th March,2020</small>
          </div>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default About;
